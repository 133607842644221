import * as React from "react";
import TopNav from "../TopNav";
import FeatureBlock from "../FeatureBlock";
import Helmet from 'react-helmet';
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";

export default function EasyUnderstandableCrashReports() {
    return (
            <>
                <Helmet>
                    <title>Crash Catch | Easy to Understand Crash Reports</title>
                    <meta name='keywords' content='crash catch, crash reports, crash summary, crash details' />
                    <meta name='description' content="With Crash Catch, there's no need to get bogged down
                    in a huge amount of irrelevant information. You need the details to identify, find and
                    fix and issue as quickly as possible to ensure your users stay happy users" />
                </Helmet>

                <div className='h-grow'>

                    <TopNav />

                    <FeatureBlock feature_title='Easy to Understand Crash Reports'
                                  sub_heading="Don't get bogged down in unnecessary data that's not relevant to your crash" />

                    <ContentWrapper>
                        <h1>Simple &amp; Understandable Crash Reports</h1>
                        <p>
                            With Crash Catch, don't get bogged down in a huge amount of irrelevant information,
                            you just want to see, what happened, when and why and that is what you get with Crash Catch.
                            All crashes and error reports will have the following information
                        </p>
                        <ul className='list-disc ml-5'>
                            <li>Date/Time of crash/error</li>
                            <li>The exception type such as "Java.lang.NullPointerException"</li>
                            <li>The exception message such as "MyVar cannot be null"</li>
                            <li>The full stacktrace of the crash</li>
                            <li>Project version</li>
                            <li>Operating System</li>
                        </ul>
                        <p className='text-sm'>
                            Extra information may be available based on the language, framework or platform you are
                            using for your project.
                        </p>

                        <p>
                            Don't just get told about issues that are unhandled - such as crash or hard failure,
                            you can get notified by any errors within your projects catch handlers and, provide
                            extra debug information so you can find it even easier to identify and fix faults
                        </p>

                        <img className='shadow-md shadow-primary/60 mx-auto my-4'
                             src='/images/screenshots/issue_details.png' alt='Crash Details' />

                    </ContentWrapper>
                    <Footer />
                </div>
            </>
        )

}