import * as React from "react";
//import {CrashCatch} from 'crashcatchlib-reactjs'
//import crash_catch from "../App";
//export const CrashCatch = React.useContext(crash_catch);
import {CrashCatchContext} from 'crashcatchlib-reactjs';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo)
    {
        this.setState({hasError: true});
        const crash_catch = this.context;
        crash_catch.reportUnhandledException(error);
    }

    render() {
        return this.props.children
    }
}
export default ErrorBoundary