import * as React from "react";
import TopNav from "./TopNav";
import Helmet from 'react-helmet';
import ContentWrapper from "./ContentWrapper";
import Highlight from "./Highlight";
import PricingOptions from "./PricingOptions";
import Footer from "./Footer";

export default function Pricing() {
    return (
        <>
            <Helmet>
                <title>Crash Catch</title>
                <meta name="keywords" content="crash reporting, error reporting, crash and error reporting, android, reactjs, php" />
                <meta name="description" content="Crash catch is a simple and affordable crash and error reporting service no matter your development stack so you can find bugs in production fast" />
            </Helmet>
            <div className='h-screen'>
                <TopNav />

                <div className='min-h-[calc(100vh-365px)] overflow-y-scroll mt-[120px] bg-[#f2f2f2] mx-2'>
                    <h1 className='text-center'>
                        Completely <Highlight>free</Highlight> for 14 days
                    </h1>
                    <p className='text-center text-xl'>
                        Try Crash Catch free for 14 days - no payment information required
                    </p>

                    <div className='text-center my-10'>
                        <a className='bg-gradient-to-r from-[#100B96] to-[#1653FD] px-4 py-3 rounded text-white font-bold text-x shadow-md active:shadow-none border-[1px] border-primary-dark'
                           href='https://app.crashcatch.com/choose-plan/'>Start Free Trial</a>
                    </div>

                    <p className='text-2xl font-bold text-center'>
                        <Highlight>Affordable</Highlight> and <Highlight>understandable</Highlight> monthly pricing
                    </p>

                    <p className='text-center text-xl my-10'>
                        We want ensure pricing is simple with a plan suitable&nbsp;
                        for where you are in your development journey. Upgrade and downgrade at any time
                    </p>

                    <PricingOptions />

                </div>
                <Footer />
            </div>
        </>
    )
}