import * as React from 'react'
import {useContext, useEffect, useState} from "react";
import axios from 'axios'
import {CrashCatchContext} from 'crashcatchlib-reactjs';

export default function useMarketingStats() {
    const [marketingStats, setMarketingStats] = useState<any>(null);
    const [marketingStatsErrors, setMarketingStatsErrors] = useState<any>(null);
    const crash_catch : CrashCatchContext = useContext(CrashCatchContext);

    const url = 'https://app.crashcatch.com/backend/api/marketing';

    useEffect(() => {
        (
            async function (){
                try
                {
                    const res = await axios.get(url);


                    const data : any = {};
                    //Data is the object from axios, the next data is my data object
                    res.data.data.forEach((param : any) => {
                        if (param.parameter === "crash_count_lifetime")
                        {
                            data.crash_count_lifetime = param.value;
                        }
                        else if (param.parameter === "device_count_lifetime")
                        {
                            data.device_count_lifetime = param.value;
                        }
                    });

                    setMarketingStats(data);

                    //setMarketingStats(null);
                }
                catch (err)
                {
                    crash_catch.reportCrash(err, "Low");
                    setMarketingStatsErrors(err);
                    console.error(err);
                }

            }
        )()
    }, []);

    return {marketingStats, marketingStatsErrors};
}