import * as React from "react";
import TopNav from "./TopNav";
import Helmet from 'react-helmet';
import Highlight from "./Highlight";
import Footer from "./Footer";
import {FaDiscord, FaEnvelope, FaGithub, FaLinkedin, FaTwitter} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import {useEffect} from "react";

export default function About() {

    const socialLinks = [
        {
            icon: FaTwitter,
            tooltip: "Follow me on Twitter",
            title: "Twitter",
            link: "https://twitter.com/chrisboard_"
        },
        {
            icon: FaGithub,
            tooltip: "See my GitHub",
            title: "GitHub",
            link: "https://github.com/boardy"
        },
        {
            icon: FaDiscord,
            tooltip: "Join my Discord",
            title: "Discord",
            link: "https://discord.gg/tbku3cG6pm"
        },
        {
            icon: FaEnvelope,
            tooltip: "Send me an Email",
            title: "Email",
            link: "mailto:chris@devso.io"
        }
    ]

    const socialClick = (link: string) => {
        window.open(link);
    }

    const Social : React.FC<{icon: any, tooltip: string, title: string, link: string}> = (props: {icon: any, tooltip: string, title: string, link: string}) => {
        const Icon = props.icon
        return (
            <div className='flex flex-row mx-3 cursor-pointer hover:underline' data-tip={props.tooltip} onClick={() => socialClick(props.link)}>
                <div>
                    <Icon className='text-4xl text-primary mr-3' />
                </div>
                <div>
                    <span className='text-4xl text-primary font-bold'>{props.title}</span>
                </div>
            </div>
        )
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    },[])

    return (
        <>
            <Helmet>
                <title>Crash Catch</title>
                <meta name="keywords" content="crash reporting, error reporting, crash and error reporting, android, reactjs, php" />
                <meta name="description" content="Crash catch is a simple and affordable crash and error reporting service no matter your development stack so you can find bugs in production fast" />
            </Helmet>
            <div className='h-screen'>
                <TopNav />

                <div className='min-h-[calc(100vh-365px)] overflow-y-auto mt-[120px] bg-[#f2f2f2] w-3/4 mx-auto'>
                    <h1>About</h1>

                    <div className='float-left m-2 p-2'>
                        <img className='rounded w-[180px] h-[180px] border-[1px] border-primary' src='/images/profile.png' alt='Chris Board Profile Picture' />
                    </div>

                    <p>
                        Crash Catch is a solo run and bootstrapped service done by myself <Highlight>Chris Board.</Highlight>
                    </p>
                    <p>
                        I built Crash Catch as I found a lot of the other crash and error reporting services are either
                        expensive or overly complicated with tonnes of features not likely to be used by hobbyist
                        developers or small teams just starting out in their journey on indie hacking.
                    </p>
                    <p>
                        Therefore, my focus is to bring an <Highlight>affordable</Highlight> and <Highlight>simple</Highlight>&nbsp;
                        implementation with the <Highlight>core features</Highlight> required to help developers like myself
                        find and fix bugs that happen once your project is released.
                    </p>
                    <p>
                        You can follow along on my journey by joining one of the links below
                    </p>

                    <div className='w-fit mx-auto text-center my-10 sizzy-red-1'>
                        <p className='font-bold text-2xl mb-3'>Join my Socials</p>
                        <div className='flex flex-row'>
                            {
                                socialLinks.map(social => {
                                    return <Social {...social} />
                                })
                            }
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
            <ReactTooltip />
        </>
    )
}