import * as React from "react";
import {LanguageSidebarProps} from "../../models/LanguageSidebarProps";
import {FaBook, FaGithub} from "react-icons/fa";
import {LinkButton, PrimaryButton} from "devso-react-library";

export default function LanguageSidebar(props: LanguageSidebarProps) {
    return (
        <div className='text-white text-xl p-4'>
            {
                props.github_repo !== null ?
                    <p>
                        <FaGithub className='text-white inline mr-4 text-xl' />
                        <a className='text-white' target='_blank' href={props.github_repo}>Official Github Repo</a>
                    </p> : null
            }

            <p>
                <FaBook className='text-white inline mr-4 text-xl' />
                <a className='text-white' target='_blank' href={props.sdk_docs}>{props.platform} Documentation</a>
            </p>

            <p className='font-bold mt-6'>Key Features</p>
            <ul className='list-disc pl-4 '>
                <li className='my-4'>Real time crash and error reports received by email</li>
                <li className='my-4'>Simple and affordable for solo developers and companies just starting out</li>
                <li className='my-4'>Lightweight SDK allows for simple installation and integration without any performance hit on your projects</li>
                <li className='my-4'>Complete control over how crashes are reported and whether or not they are notified</li>
                <li className='my-4'>Simple API allowing you to submit crash and error reports for languages and frameworks not officially supported</li>
                <li className='my-4'>Unlimited team members no matter what plan you are on</li>
            </ul>

            <div className='items-center content-center text-center'>
                <button className='bg-cta p-4 rounded text-center text-white mt-10 shadow-md shadow-primary hover:bg-primary-light transition-all ease-in-out duration-300 active:shadow-none'
                        onClick={() => window.location.href='https://app.crashcatch.com/choose-plan'}>Start Your Free 14 Day Trial</button>
            </div>


        </div>
    )
}