import * as React from "react";
import {BlogPostProps} from "../../models/BlogPostProps";
import {convertFullTimezoneStringToNormalString} from "../../utils/DateTimeManager";
import {useNavigate} from "react-router-dom";

export default function BlogCard(props: BlogPostProps) {
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate(`/blog/${props.slug}`)} className='min-w-3/4 max-3/4 bg-white p-4 border-[1px] border-gray-400 rounded m-4 cursor-pointer shadow-sm shadow-primary/60 hover:shadow-none transition-all ease-in-out duration-300'>
            {
                props.feature_image !== null ?
                    <div className='flex flex-col lg:flex-row'>
                        <div className='mx-3 items-center justify-items-center my-auto'>
                            <img src={props.feature_image} />
                        </div>
                        <div>
                            <h2>{props.title}</h2>
                            <p>
                                {
                                    props.html.replace(/<[^>]+>/g, '').substring(0, 400)
                                }...
                            </p>
                        </div>
                    </div> :
                    <div>
                        <h2>{props.title}</h2>
                        <p>
                            {
                                props.html.replace(/<[^>]+>/g, '').substring(0, 400)
                            }...
                        </p>
                    </div>
            }

                <div className='grid grid-cols-2'>
                    <span className='text-sm text-gray-500 ml-3 mt-4 inline w-fit'>
                        Published @ {convertFullTimezoneStringToNormalString( props.published_at)}
                    </span>

                    <span className='text-sm text-gray-500 ml-3 mt-4 text-right inline w-full pr-4'>
                        Estimated Read Time: {props.reading_time} minutes
                    </span>
                </div>

        </div>
    )
}
