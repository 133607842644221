import * as React from 'react'
import { DefaultProps } from 'devso-react-library'

export default function ContentWrapper(props: DefaultProps) {
    return (
        <main className='!flex-grow min-h-[calc(100vh-365px)] overflow-y-scroll mt-[120px] bg-[#f2f2f2]'>
            <div className='w-full p-4 sm:w-3/4 mx-auto sizzy-green-2'>
                {props.children}
            </div>
            
        </main>
    )
}
