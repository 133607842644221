import * as React from "react";
import TopNav from "./TopNav";
import Helmet from 'react-helmet';

const OnHold : React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Crash Catch - Crash Reporting for Every Developer</title>
                <meta name="keywords" content="crash reporting, error reporting, crash and error reporting, android, reactjs, php" />
                <meta name="description" content="Crash catch is a simple and affordable crash and error reporting service no matter your development stack so you can find bugs in production fast" />
            </Helmet>
            <div className='h-screen'>
                <TopNav />

                <div className='min-h-[calc(100vh-365px)] overflow-y-auto mt-[120px] bg-[#f2f2f2] w-3/4 mx-auto'>
                    <h1>Future of Crash Catch</h1>
                    <p>
                        We're sorry to say, that at the moment, sign ups to Crash Catch are currently on hold.
                    </p>
                    <p>
                        Crash Catch hasn't worked in the way that I wanted to, both in terms of functionality
                        and languages available, but also in terms of how the backend system is managed for
                        our development and maintenance.
                    </p>
                    <p>
                        Due to this, we are currently disabling sign ups to Crash Catch but existing users
                        will continue to function and be supported.
                    </p>
                    <p>
                        We want to ensure though, that Crash Catch isn't a dead project, we are not finished
                        as we still want to make a simple and affordable crash and error reporting service
                        for every user, but we're going to go back to the drawing board and have a rethink on
                        how best to implement it.
                    </p>
                    <p>
                        We apologise for any inconvenience.
                    </p>

                    <p>
                        Thanks for your interest
                    </p>
                    <p className='font-bold'>
                        Chris Board
                    </p>
                    <p className='p-0 m-0'>
                        Founder/Developer
                    </p>
                </div>
            </div>
        </>
    )
}

export default OnHold;