import * as React from 'react'
import { NavLinkProps } from '../models/Nav'
import { FaArrowLeft, FaBars } from 'react-icons/fa'
import { useState } from 'react'
import {NavLink, useLocation} from "react-router-dom";
import {NavMenu, NavItem} from "devso-react-library";

export default function TopNav() {

    const nav_links : Array<NavLinkProps> = [
        {
            href: '/',
            target: null,
            title: 'Home',
            internal_link: true
        },
        {
            href: '/blog',
            target: null,
            title: 'Blog',
            internal_link: true
        },
        {
            href: 'https://docs.crashcatch.com',
            target: '_blank',
            title: 'Docs',
            internal_link: false
        },
        {
            href: '/features',
            target: '',
            title: 'Features',
            items: [
                {
                    href: '/features/active-user-analysis',
                    target: '',
                    title: 'Active User Analysis',
                },
                {
                    href: '/features/project-release-detection',
                    target: '',
                    title: 'Project Release Detection'
                },
                {
                    href: '/features/simple-understandable-crash-reports',
                    target: '',
                    title: 'Easy & Understandable Crash Reports'
                },
                {
                    href: '/features/real-time-monitoring',
                    target: '',
                    title: 'Real Time Monitoring'
                },
                {
                    href: '/languages',
                    target: '',
                    title: 'Available Platforms'
                }
            ]
        },
        {
            href: '/pricing',
            target: '',
            title: 'Pricing',
            internal_link: true
        },
        {
            href: 'https://support.devso.io',
            target: '_blank',
            title: 'Support',
            internal_link: false
        },
        {
            href: '/about',
            target: '',
            title: "About",
            internal_link: true
        },
        {
            href: 'https://app.crashcatch.com',
            title: 'Login',
            target: '',
            internal_link: false
        }
    ]

    const navigate = useLocation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    return (
        <div className='w-full bg-white h-[110px] shadow-primary shadow-md fixed z-[999] text-primary shadow-sm shadow-primary/60'>
            <nav className='w-3/4 sm:w-3/4 md:w-full lg:w-3/4 mx-auto p-3'>
                <div className='relative flex flex-col-2'>
                    <div className='w-full sm:w-auto'>
                        <div className='inline sm:hidden absolute bottom-0 mb-10'>
                            {
                                !mobileMenuOpen ?
                                    <FaBars onClick={() => setMobileMenuOpen(true)} className='inline cursor-pointer text-primary hover:bg-primary-light/20 transition-all' />
                                    : <FaArrowLeft onClick={() => setMobileMenuOpen(false)} className='inline cursor-pointer text-primary hover:bg-primary-light/20 transition-all' />
                            }

                        </div>
                        <div className='text-center sm:text-left md:text-left lg:text-left'>
                            <img className='w-[127px] h-[87px] mx-auto text-center' src='/images/logo.png' alt='Crash Catch Logo'/>
                        </div>
                    </div>

                    <div className='hidden sm:relative sm:flex items-center justify-end justify-items-end w-full space-x-8 md:space-x-4 mr-10'>
                        {
                            nav_links.map((link, index) => {
                                if (typeof link.items === typeof undefined || link.items.length === 0) {
                                    if (link.internal_link) {
                                        console.log("navigation name:", navigate);
                                        return (
                                            <NavLink className={`text-primary ${link.href === navigate.pathname ? 'font-bold underline' : ''}`} key={link.href} to={link.href}>
                                                {link.title}
                                            </NavLink>
                                        )
                                    } else {
                                        return (
                                            <a key={index} href={link.href} target={link.target !== null ? link.target : null}
                                               className={`text-primary ${link.href === navigate.pathname ? 'font-bold underline' : ''}`}>{link.title}</a>
                                        )
                                    }
                                }
                                else
                                {
                                    return (
                                        <NavMenu label={link.title}>
                                            {
                                                link.items.map(item => {
                                                    return (
                                                        <NavItem key={index} href={item.href} title={item.title}
                                                             target={item.target} internal_link={item.internal_link} />
                                                    )
                                                })
                                            }
                                        </NavMenu>
                                    )
                                }
                            })
                        }
                        <a className='bg-cta p-3 hover:bg-primary-light transition-all ease-in-out duration-300 text-white rounded' href='https://app.crashcatch.com/choose-plan'>Signup</a>
                    </div>
                </div>
            </nav>
            <div className={`w-3/4 bg-gray-300 p-4 border-primary border-1 shadow-lg shadow-primary h-screen !z-[900] absolute
                transition-width transition-duration-200 ${mobileMenuOpen ? "w-3/4" : "w-0 p-0"}`}>
                <ul className={`${mobileMenuOpen ? "block" : "hidden"}`}>
                    {
                        nav_links.map((link, index) => {
                            if (typeof link.items === typeof undefined || link.items.length === 0)
                            {
                                if (link.internal_link)
                                {
                                    return (
                                        <li className='py-2 text-white' key={link.href}>
                                            <NavLink className={`text-primary ${link.href === navigate.pathname ? 'font-bold' : ''}`} to={link.href}>
                                                {link.title}
                                            </NavLink>
                                        </li>
                                    )
                                }
                                else
                                {
                                    return (
                                        <li className='py-2 text-primary' key={link.href}>
                                            <a href={link.href} target={link.target !== null ? link.target : null}
                                               className={link.href === navigate.pathname ? 'font-bold underline' : undefined}>{link.title}</a>
                                        </li>
                                    )
                                }
                            }
                            else
                            {
                                return (
                                    <NavMenu label={link.title} custom_classes='py-4 text-primary'>
                                        {
                                            link.items.map(item => {
                                                return (
                                                    <NavItem href={item.href} title={item.title}
                                                             target={item.target} internal_link={item.internal_link} />
                                                )
                                            })
                                        }
                                    </NavMenu>
                                )
                            }
                        })
                    }
                    <li className='py-2 key='>
                        <a className='bg-cta p-3 hover:bg-primary-light transition-all ease-in-out duration-300 text-white rounded' href='https://app.crashcatch.com/choose-plan'>Signup</a>
                    </li>

                </ul>
            </div>

        </div>
    )
}
