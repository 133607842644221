import * as React from "react";
import TopNav from "./TopNav";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";
import { CrashCatchContext } from 'crashcatchlib-reactjs';
import {useContext, useEffect, useRef} from "react";
import {Link, NavLink} from 'react-router-dom';

export default function NotFound() {

    const crash_catch : CrashCatchContext = useContext(CrashCatchContext);

    useEffect(() => {
        try
        {
            throw "NotFound"
        }
        catch (err)
        {
            crash_catch.reportCrash(err, "Low");
        }
    }, []);


    return (
        <div className='h-grow'>

            <TopNav />

            <ContentWrapper>
                <div className='flex'>
                    <div className='m-auto text-center'>
                        <h1 className='text-4xl'>Page Not Found</h1>
                        <img className='w-1/3 mx-auto' src='/images/logo.png' alt='Crash Catch Logo' />
                        <p className='text-2xl font-bold'>
                            Sorry the page you were looking for was not found.
                        </p>
                        <p className='text-xl pb-4'>
                            Lets see if we can get you back on track
                        </p>

                        <NavLink key='/' to='/'>
                            Home
                        </NavLink>&nbsp;|&nbsp;

                        <NavLink key='/blog' to='/blog'>
                            <a>Crash Catch Blog</a>&nbsp;|&nbsp;
                        </NavLink>

                        <a href='https://docs.crashcatch.com' target='_blank'>Crash Catch Documentation</a>&nbsp;|&nbsp;

                    </div>
                </div>


            </ContentWrapper>
            <Footer />
        </div>
    )
}