import * as React from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import Helmet from 'react-helmet'

function AndroidInstructions()
{
    return (
        <>
            <Helmet>
                <title>Crash Catch | Android Integration</title>
                <meta name='keywords' content='crash catch, android integration' />
                <meta name='description' content="With Crash Catch's Android SDK it is simple to integrate in to your
                    Android Java projects and receive crashes and errors in just a couple of minutes" />
            </Helmet>
            <div className='pb-10'>
                <h2>Installation</h2>
                <p>
                    The Crash Catch android library, can be installed directly from GitHub using JitPack.
                </p>
                <p>
                    In your settings.gradle under dependencyResolutionManagement at the root of your project, you need to add the following:
                </p>

                <SyntaxHighlighter language='gradle'>
                    {`
maven { url 'https://jitpack.io' }
                `}
                </SyntaxHighlighter>
                <p>
                    For example, the section may look something like the below
                </p>
                <SyntaxHighlighter language='gradle'>
                    {`
dependencyResolutionManagement {
    repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)
    repositories {
        google()
        mavenCentral()
        maven { url 'https://jitpack.io' }
    }
}   
                `}
                </SyntaxHighlighter>
                <p>
                    Then in the dependencies section add the library as below:
                </p>
                <SyntaxHighlighter language='java'>
                    {`
dependencies {
    implementation 'com.github.Crash-Catch:CrashCatchLib-Android:TAG'
    implementation 'com.squareup.okio:okio:1.17.2'
    implementation 'com.squareup.okhttp3:okhttp:3.12.2'
}
                `}
                </SyntaxHighlighter>
                <p>
                    Where TAG is the latest tagged release version number at https://github.com/Crash-Catch/CrashCatchLib-Android/releases.
                </p>
                <h2>Configuration</h2>
                <p>
                    If your android project has multiple different activities, then every activity will need to
                    initialise Crash Catch. Therefore the easiest thing to do is to create a class of type activity
                    and set up the Crash Catch library in this class, and then in your main activity class(es)
                    extend this new class.
                </p>
                <p>
                    An example is below:
                </p>
                <p style={{fontWeight: 'bold'}}>BaseActivity.java</p>
                <SyntaxHighlighter language="java">
                    {`
class BaseActivity extends AppCompatActivity
{
    protected CrashCatch;
    protected void onCreate(Bundle savedInstanceParams)
    {
        super.onCreate(savedInstanceState);
        //Initialise CrashCatch here\n
    }
}
                `}
                </SyntaxHighlighter>
                <p style={{fontWeight: 'bold'}}>MainActivity.java</p>
                <SyntaxHighlighter language="java">
                    {`
class MainActivity extends BaseActivity
{
    protected void onCreate(Bundle savedInstanceState)
    {
        super.onCreate(savedInstanceState);
        //Your main activity application logic
    }
}
                `}
                </SyntaxHighlighter>
                <p>
                    To initialise Crash Catch you can do the following:
                </p>
                <SyntaxHighlighter language="java">
                    {`
CrashCatch.Initialise(this, "<api_key>", "<project_id>", "<version_number>");
                `}
                </SyntaxHighlighter>
                <p>
                    Then to send crash, in the onCreate of your activity, run the following:
                </p>
                <SyntaxHighlighter language='java'>
                    {`
CrashCatch.ReportCrash(new Exception("Test Crash Catch Error"), CrashCatch.CrashSeverity.Medium);
                `}
                </SyntaxHighlighter>
                <p>
                    For more information to see other examples of how to send crashes, check
                    the <a href='https://github.com/Crash-Catch/CrashCatchLib-Android' target='_blank' rel='nofollow noreferrer'>GitHub</a> repository.
                </p>
            </div>
        </>

    )
}

export default AndroidInstructions;