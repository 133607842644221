import * as React from 'react'
import {FaDiscord, FaEnvelope, FaFacebook, FaLinkedin, FaPhone, FaTwitter} from 'react-icons/fa'
import {Link, NavLink} from 'react-router-dom'


export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className='bg-white border-t-[1px] border-t-primary text-black text-white w-full shadow-md shadow-primary border-t-[1px] border-primary mt-5 pb-3 bottom-0'>
            <div className='container mx-auto items-center justify-items-center'>
                <div className='flex flex-col sm:flex-row '>
                    <div className='sm:m-6 w-full sm:w-1/3'>
                        <img src='/images/logo.png' alt='Crash Catch Logo' className='w-[240px] mx-auto sm:mx-0' />
                        <p className='mr-4 ml-6'>
                            Crash catch is a simple and affordable crash and error reporting service
                            no matter your development stack helping you to improve your projects from web apps,
                            APIs and mobile apps and see what's going wrong in production.
                        </p>
                    </div>

                    <div className='m-6 text-primary sm:mx-10'>
                        <p className='font-bold'>Features</p>
                        <ul>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/features/active-user-analysis'>
                                    Active User Analysis
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/features/project-release-detection'>
                                    Project Release Detection
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/features/simple-understandable-crash-reports'>
                                    Easy &amp; Understandable Crash Reports
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/features/real-time-monitoring'>
                                    Real Time Monitoring &amp; Alerting
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/languages'>
                                    Available Platforms
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className='m-6 text-primary sm:mx-10'>
                        <p className='font-bold'>Useful Links</p>
                        <ul>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/blog'>
                                    Blog
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <a href='https://docs.crashcatch.com' className='text-primary'>Documentation</a>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/pricing'>
                                    Pricing
                                </NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/privacy-policy'>Privacy Policy</NavLink>
                            </li>
                            <li className='py-2'>
                                <NavLink className='text-primary' to='/terms-of-service'>Terms of Service</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='hidden lg:block'>
                    <hr className='my-12 text-white' />

                    <div className='grid grid-cols-2 text-sm mx-6 py-6'>
                        <div>
                            &copy; 2021 - {year} - Crash Catch
                        </div>
                        <div className='text-right'>
                            <a href='https://facebook.com/devsoio' title='Follow us on Facebook' target='_blank'><FaFacebook className='w-5 h-5 text-primary' /></a>
                            <a href='https://twitter.com/devso_io' title='Follow us on Twitter' target='_blank'><FaTwitter className='w-5 h-5 text-primary' /></a>
                            <a href='https://discord.gg/cbfsN6MyV6' title='Join our Discord' target='_blank'><FaDiscord className='w-5 h-5 text-primary' /></a>
                        </div>
                    </div>
                </div>

                <div className='lg:hidden block text-center my-14'>
                    <div className='my-6'>
                        <a href='https://facebook.com/devsoio' title='Follow us on Facebook' target='_blank'><FaFacebook className='w-5 h-5 text-primary' /></a>
                        <a href='https://twitter.com/devso_io' title='Follow us on Twitter' target='_blank'><FaTwitter className='w-5 h-5 text-primary' /></a>
                        <a href='https://discord.gg/cbfsN6MyV6' title='Join our Discord' target='_blank'><FaDiscord className='w-5 h-5 text-primary' /></a>
                    </div>


                    &copy; 2021 - {year} - Crash Catch
                </div>

            </div>
        </footer>
    )

    /*return (
        <footer className='bg-primary text-white w-full shadow-md shadow-primary border-t-[1px] border-primary mt-5 pb-3 bottom-0'>
            <div className='w-full justify-center sm:w-3/4 mx-auto flex grid-cols-2 space-x-14 items-center'>
                <div className='flex flex-col w-auto items-center'>
                    <p className='text-sm text-center pt-1 my-0 font-bold'>
                        Crash Catch provided by
                    </p>
                    <img className='w-[100px] h-[109px]' src='/images/devso_logo.png' alt='Devso Logo'/>
                    <span className='text-sm'>Copyright &copy; {year}</span>
                    <div className='grid grid-cols-3 mt-3 space-x-3'>
                        <a href='https://facebook.com/devsoio' target='_blank'><FaFacebook className='w-10 h-10 text-white' /></a>
                        <a href='https://twitter.com/devso_io' target='_blank'><FaTwitter className='w-10 h-10 text-white' /></a>
                        <a href='http://linkedin.com/company/boardies-it-solutions' target='_blank'><FaLinkedin className='w-10 h-10 text-white' /></a>
                    </div>
                </div>

                <div className='hidden sm:flex flex-row space-x-20 justify-end items-end text-right  w-full mx-auto'>
                    <div>
                        <p>
                            <a className='text-white' target='_blank' href='https://support.devso.io'>Support Portal</a>
                        </p>
                        <p>
                            <a className='text-white' target='_blank' href='https://status.devso.io'>Service Status</a>
                        </p>
                        <p>
                            <FaPhone className='inline mr-2' />  +44 (0) 2393 162487
                        </p>
                        <p>
                            <FaEnvelope className='inline mr-2' /> <a className='text-white' href='mailto://support@devso.io'>info@devso.io</a>
                        </p>
                        <p className='text-white'>
                            <span className='text-sm'>Crash Catch - Copyright &copy; 2021 - {year}</span>
                        </p>
                    </div>
                </div>
                
            </div>
            
            
        </footer>
    )*/
}