import * as React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import useMarketingStats from "../hooks/useMarketingStats";
import {FaChartLine, FaClock, FaCode, FaCoins, FaLock} from "react-icons/fa";
import {useEffect} from "react";
import Helmet from 'react-helmet';
import Highlight from "./Highlight";
import PricingOptions from "./PricingOptions";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function Home() {
    const {marketingStats, marketingStatsErrors} = useMarketingStats();


    useEffect(() => {

        if (window.location.href.indexOf('#') !== -1)
        {
            document.getElementById('pricing').scrollIntoView({behavior: 'smooth'});
        }

    }, [])

    const returnTidyNumber = (number: number) : string => {
        if (number >= 1000 && number <= 999999) {
            return Math.round(number / 1000) + "K+";
        } else if (number >= 1000000 && number < 99999999) {
            return Math.round(number / 1000000) + "M+";
        }
        else if (number >= 1000000000)
        {
            return Math.round(number / 1000000000) + "B+";
        }
        return number.toString();
    };


    return (
        <>
            <Helmet>
                <title>Crash Catch - Crash Reporting for Every Developer</title>
                <meta name="keywords" content="crash reporting, error reporting, crash and error reporting, android, reactjs, php" />
                <meta name="description" content="Crash catch is a simple and affordable crash and error reporting service no matter your development stack so you can find bugs in production fast" />
            </Helmet>
            <div className='h-grow'>

                <TopNav />

                <div className='text-primary !flex-grow min-h-[calc(100vh-365px)] overflow-y-scroll mt-[110px] bg-[#f2f2f2] py-4'>

                    <div className='my-20 w-full px-2 md:w-3/4 mx-auto flex md:flex-row flex-col'>
                        <div className='align-middle my-auto text-center '>
                            <img className='w-1/4 md:w-1/3 mx-auto my-4 text-center' src='/images/logo.png' alt='Crash Catch Logo'/>
                            <h2 className='text-3xl'>Stop relying on customers reporting bugs!</h2>
                            <p className='text-center text-2xl leading-9'>
                                The <Highlight>last</Highlight> crash reporting service
                                you'll need with <Highlight>real time</Highlight> reporting
                                and <Highlight>project release detection</Highlight>
                            </p>
                        </div>
                        <div className='w-full my-4 md:my-0'>
                            <img className='drop-shadow-xl shadow-primary' src='/images/hero_image.png' alt='Crash Catch Hero' />
                        </div>
                    </div>

                    <p className='text-center text-3xl my-4'>
                        Stop relying on your customers reporting problems and identify issues in production&nbsp;
                        <span className='underline decoration-white font-bold'>fast</span>
                    </p>

                    <div className='text-center mx-auto py-4'>
                        <a href='https://app.crashcatch.com/choose-plan'
                           className='bg-gradient-to-r from-primary-dark to-primary-light rounded p-4 text-white transition-all ease-in-out hover:from-primary-light hover:to-primary-light active:from-primary-dark active:to-primary-dark'>
                            Start your 14 day free trial
                        </a>
                        <p className='my-4'>
                            No credit card required
                        </p>
                    </div>

                </div>

                <div className='py-20'>
                    <h1 className='font-bold text-3xl text-center'>
                        Crash reporting for solo and startup developers no matter your development stack
                    </h1>

                    <p className='text-3xl text-center'>
                        We make crash and error reporting simple and affordable
                    </p>

                    <div className='flex flex-row flex-wrap items-center justify-items-center my-auto text-left w-3/4 mx-auto my-8'>

                        <div className='flex-1 text-center m-4'>
                            <FaClock className='text-4xl font-bold text-center text-primary mx-auto' />
                            <p className='font-bold text-center mx-auto my-2'>
                                Real Time Crash Notification
                            </p>
                        </div>

                        <div className='flex-1 text-center m-4'>
                            <FaChartLine className='text-4xl font-bold text-center text-primary mx-auto' />
                            <p className='font-bold text-center mx-auto my-2'>
                                Active User Analysis &amp; Project Release Detection
                            </p>
                        </div>

                        <div className='flex-1 text-center m-4'>
                            <FaCode className='text-4xl font-bold text-center text-primary mx-auto' />
                            <p className='font-bold text-center mx-auto my-2'>
                                Cross Platform, Framework and Language
                            </p>
                        </div>

                        <div className='flex-1 text-center m-4'>
                            <FaCoins className='text-4xl font-bold text-center text-primary mx-auto' />
                            <p className='font-bold text-center mx-auto my-2'>
                                Affordable &amp; Simple
                            </p>
                        </div>

                        <div className='flex-1 text-center m-4'>
                            <FaLock className='text-4xl font-bold text-center text-primary mx-auto' />
                            <p className='font-bold text-center mx-auto my-2'>
                                Secure
                            </p>
                        </div>
                    </div>
                </div>

                <div className='text-center py-12'>
                    <img className='mx-auto shadow-md rounded shadow-primary/60' src='/images/screenshots/dashboard.png' alt='Crash Catch Dashboard' />
                </div>

                {
                    marketingStats !== null ?
                        <div className='bg-primary mt-20 w-full text-white clear-both my-12 items-center justify-items-center my-auto mb-20'>
                            <div className='w-3/4 mx-auto items-center justify-center justify-items-center'>
                                <div className='grid grid-cols-2 text-center items-center justify-items-center my-auto'>

                                    <div className='text-center'>
                                        <p className='text-3xl font-bold text-center'>
                                            Crashes Processed
                                        </p>
                                        <VisibilitySensor partialVisibility offset={{bottom: 100}}>
                                            {({ isVisible }) => (
                                                <div className='text-center text-2xl' style={{ height: 40 }}>
                                                    {isVisible ? <CountUp separator=',' end={marketingStats.crash_count_lifetime} /> : 0}
                                                    +
                                                </div>
                                            )}
                                        </VisibilitySensor>
                                        {/*<p className='text-center text-2xl'>
                                            {marketingStats.crash_count_lifetime}
                                        </p>*/}
                                    </div>

                                    <div className='text-center'>
                                        <p className='text-3xl font-bold text-center'>
                                            Unique Devices Processed
                                        </p>
                                        {/*<p className='text-center text-2xl'>*/}
                                            <VisibilitySensor partialVisibility offset={{bottom: 100}}>
                                                {({ isVisible }) => (
                                                    <div className='text-center text-2xl' style={{ height: 40 }}>
                                                        {isVisible ? <CountUp separator=',' end={marketingStats.device_count_lifetime} /> : 0}
                                                        +
                                                    </div>
                                                )}
                                            </VisibilitySensor>

                                            {/*arketingStats.device_count_lifetime*/}
                                        {/*</p>*/}
                                    </div>

                                </div>
                            </div>
                        </div> : null
                }

                <div className='p-16'>
                    <div className='flex flex-col lg:flex-row'>
                        <img className='shadow-md rounded shadow-primary/60 mx-10 w-full lg:w-1/2 mx-auto' src='/images/screenshots/active_user_crash_analysis.png'
                             alt='Active User Analysis & Project Release Detection' />

                        <div className='mx-4 items-center justify-items-center justify-center my-auto'>
                            <h2>Active User Analysis, Crash Analysis & Project Release Detection</h2>
                            <p>
                                With Crash Catch, easily identify unique active users and crash trends along with
                                automatically detecting when new project versions are detected being overlaid on the charts
                                so you can see the trends of to identify whether new versions
                                have an affect, good or bad
                            </p>
                        </div>
                    </div>
                </div>

                <div className='bg-white p-16'>
                    <div className='flex flex-col-reverse lg:flex-row'>
                        <div className='mx-4 items-center justify-items-center justify-center my-auto'>
                            <h2>Easy to understand Crash Summary</h2>
                            <p>
                                View a quick overview of a crash group showing how often the crash is received,
                                where the error has occurred, and what versions are of your project are affected by
                                this crash group
                            </p>
                        </div>

                        <img className='shadow-md rounded shadow-primary/60 mx-10 w-fit lg:w-1/2 mx-auto' src='/images/screenshots/issue_details.png'
                             alt='Easy to understand Crash Summary' />
                    </div>
                </div>

                <div className='p-16'>
                    <div className='flex flex-col lg:flex-row'>
                        <img className='mx-auto shadow-md rounded shadow-primary/60 mx-10 w-full lg:w-1/2' src='/images/screenshots/issue_details_stack.png'
                             alt='Easy to follow and understand crash reports' />

                        <div className='mx-4 items-center justify-items-center justify-center my-auto'>
                            <h2>Easy to Follow and Understand Crash Reports</h2>
                            <p>
                                Easy to follow and understand crash reports for each incident, showing you the information
                                yoy need to find, debug and resolve issue, no getting bogged down in a deluge of information
                                irrelevant to a crash report. You also have the ability to provide extra debug information
                                where required to make debugging even simpler*.
                            </p>
                            <p className='text-sm'>
                                *Applies to crash reports that are handled, i.e. submitted via a try/catch statement
                            </p>
                        </div>
                    </div>
                </div>

                <div className='text-center py-6 my-8'>
                    <p className='text-3xl font-bold pb-4'>
                        Want to see what's happening in production across all of your projects
                    </p>
                    <p className='text-xl pb-9'>
                        Start your free 14 day trial and start receiving crashes within a couple of minutes
                    </p>

                    <a className='bg-gradient-to-r from-primary-dark to-primary-light rounded p-4 text-white transition-all ease-in-out hover:from-primary-light hover:to-primary-light active:from-primary-dark active:to-primary-dark' href='https://app.crashcatch.com/choose-plan'>Start Your Free 14 Day Trial</a>
                </div>

                <div id='pricing'>
                    <PricingOptions />
                </div>


                <Footer />
            </div>
        </>

    )
}