import * as React from "react";
import TopNav from "./TopNav";
import ContentWrapper from "./ContentWrapper";
import GroupToggleButtons, {ButtonOptions} from "./GroupToggleButtons";
import Footer from "./Footer";
import {useNavigate} from "react-router-dom";
import Helmet from 'react-helmet'

export default function Languages() {

    const groupToggleButtons : Array<ButtonOptions> = [
        {
            label: "Android",
            subTitle: 'Java',
            selected: false,
            icon: '/images/platforms/android.png'
        },
        {
            label: 'ReactJS',
            selected: false,
            icon: '/images/platforms/reactjs.png'
        },
        {
            label: "PHP",
            selected: false,
            icon: '/images/platforms/php.png'
        },
        {
            label: "Flutter",
            selected: false,
            selectable: true,
            icon: '/images/platforms/flutter.png'
        },
        {
            label: "Custom SDK",
            selected: false,
            selectable: true,
            icon: '/images/logo.png'
        }
    ]

    const navigate = useNavigate();

    const handleToggleChange = (platform) => {
        console.log("Selection made: ", platform)

        navigate(`/languages/${platform.toLowerCase().replace(' ', '-')}`)
    }

    return (
        <>
            <Helmet>
                <title>Crash Catch | Languages &amp; Frameworks</title>
                <meta name='keywords' content='crash catch, languages, frameworks, languages and frameworks, android, reactjs, php, cross platform' />
                <meta name='description' content='Crash Catch is a cross platform any language/any
                platform crash and error reporting services, with official libraries ranging from mobile apps,
                web apps and APIs with more to come. There is also a simple and easy to use API for sending
                crash reports for languages and frameworks which are not yet officially supported' />
            </Helmet>
            <div className='h-grow'>

                <TopNav />

                <ContentWrapper>
                    <h1 className='text-center'>Languages &amp; Frameworks</h1>
                    <div className='w-3/4 mx-auto'>
                        <p className='text-xl font-bold'>
                            With Crash Catch, you can easily integrate the Crash Catch SDK into your projects
                            no matter your development stack and start receiving errors for issues identified
                            in production and see active user trends and crash trends as updates are released.
                        </p>
                        <p className='text-xl'>
                            If there's currently no library available for your language or framework, not to worry,
                            Crash Catch has a simple and easy to use API so you can submit errors for other project types,
                            but we are looking at adding more very soon.
                        </p>
                    </div>

                    <div className='mx-auto items-center justify-items-center w-full text-center content-center'>


                        <div className='text-center items-center justify-items-center content-center'>
                            <div className='flex flex-auto flex-wrap justify-center'>
                                {
                                    groupToggleButtons.map(button => {
                                        return (
                                            <div onClick={() => handleToggleChange(button.label)}
                                                 className='text-center shadow-lg cursor-pointer m-4 w-[140px] py-10 pb-10 px-5 hover:bg-primary-light/20 transition-all ease-in-out duration-300'>

                                                <img className='mx-auto h-[60px] w-[60px] mb-2' src={button.icon} alt={button.label} />
                                                <span className='font-bold'>
                                                {button.label}
                                            </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>

                    <p className='text-center font-bold mt-10'>
                        Want to see how to implement your own SDK for a custom language or framework, select Custom SDK
                        above or check out our docs at <a href='https://docs.crashcatch.com'>https://docs.crashcatch.com</a>
                    </p>


                </ContentWrapper>




                <Footer />
            </div>
        </>

    )
}