import * as React from "react";
import Helmet from 'react-helmet';

function CustomInstructions()
{
    return (
        <>
            <Helmet>
                <title>Crash Catch | Custom SDK</title>
                <meta name='keywords' content='custom sdk, simple api, custom crash reporting, custom error reporting' />
                <meta name='description' content="With Crash Catch's simple API, you can easily create a custom SDK for your language or framework if it is it not yet officially supported" />
            </Helmet>
            <div>
                <div className='text-center items-center justify-items-center content-center'>
                    <img className='ml-auto mr-auto' style={{width: 120}} src='/images/logo.png' alt='Custom SDK' />
                </div>

                <h2>Installation</h2>

                <p className='font-bold'>
                    Below you will find your project settings, you will need these to send
                    initialisation and crash requests within your crash reporting library.
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Project ID: </span> {`<your_project_id>`}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>API Key: </span> {`<your_api_key>`}
                </p>

                <p>
                    For information as to how to create your own crash reporting library then you can check
                    our <a href='https://docs.crashcatch.com' rel='noopener noreferrer' target='_blank' title='Crash Catch Documentation'>documentation site</a>.
                </p>
            </div>
        </>

    )
}

export default CustomInstructions;