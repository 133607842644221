import * as React from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import Helmet from 'react-helmet';

function FlutterInstructions() {
    return (
        <>
            <Helmet>
                <title>Crash Catch | Flutter Integration</title>
                <meta name='keywords' content='crash catch, flutter integration' />
                <meta name='description' content="With Crash Catch's Flutter SDK it is simple to integrate in to your
                    Flutter projects and receive crashes and errors in just a couple of minutes" />
            </Helmet>
            <div className='pb-10'>
                <h2>Supported Platforms</h2>
                The Crash Catch Flutter library supports the following platforms:
                <ul className='list-disc mx-10'>
                    <li>Android</li>
                    <li>iOS/iPadOS</li>
                    <li>Windows Desktop</li>
                    <li>Web</li>
                </ul>

                <h2>Installation</h2>
                <p>
                    The first thing to do is to add the Crash Catch library to your pubspec.yaml file under the
                    dependencies. An example is below, ensure that the version number is the latest version
                    number that is shown on the GitHub repositories tags.
                </p>

                <SyntaxHighlighter language='yaml'>
                    {`
dependencies:
    flutter:
        sdk: flutter
    crashcatchlib_flutter: ^TAG_VERSION
    `}
                </SyntaxHighlighter>
                <p>Change TAG_VERSION above to be the latest tag version you can find on GitHub
                    at <a href='https://github.com/Crash-Catch/CrashCatchLib-Flutter/tags'>https://github.com/Crash-Catch/CrashCatchLib-Flutter/tags</a>
                </p>

                <p>
                    Then run the Pub get command to update the dependencies as below:
                </p>
                <SyntaxHighlighter language='shell'>
                    flutter pub get
                </SyntaxHighlighter>

                <h2>Using the Library</h2>
                <p>
                    If you project has multiple different screens, then you need to pass an instance of Crash Catch to
                    the class Constructor (this is the recommended and most efficient use) or initialise on
                    each new screen, and have a helper method that you can from anywhere to initialise and be
                    able to update your API key or project ID in one place if you ever need to.
                </p>

                <p>
                    Import the Crash Catch project into your class, as shown below:
                </p>

                <SyntaxHighlighter language='dart'>
                    import 'package:crashcatchlib_flutter/crashcatchlib_flutter.dart';
                </SyntaxHighlighter>

                <p>
                    Then create an instance of the library and passing in a BuildContext and send the initialisation request as follows:
                </p>

                <SyntaxHighlighter language='dart'>
                    {`
class Home extends StatelessWidget {
    @override
    Widget build(BuildContext context) {
        CrashCatch crashCatch = new CrashCatch(context);
        crashCatch.initialiseCrashCatch("<project_id>", "<api_key>", "<project_version>");
        return (
            //Create your widget here
        );
    }
}
            `}
                </SyntaxHighlighter>

                <p>
                    In the above example, you should update &lt;your_project_id&gt; and &lt;api_key&gt; from the project
                    settings page which can be copied directly to your clipboard from the Crash Catch website.
                    The &lt;project_version&gt; should be updated on each version release.
                </p>

                <p>
                    If all you are worried about is capturing unhandled exceptions, then that's all you need to
                    do, however, you report errors that occur within try/catch blocks as and when required.
                </p>

                <h2>Reporting Errors</h2>
                <p>
                    Unhandled crashes and errors are automatically reported once Crash Catch is initialised.
                    However, you can report errors that are caught within a try/catch block.
                </p>
                <p>
                    This is done by calling the reportCrash method within the CrashCatch object. This takes two
                    parameters, the exception object, and the severity, and an optional third parameter being the stack.
                </p>
                <p>
                    If you don't want to pass the stack, then that's not a problem, if you don't the Crash Catch
                    library, will automatically detect the stack for the current thread regardless and use
                    that to send as part of the error. The second parameter is the severity, which can be
                    one of three options, LOW, MEDIUM and HIGH using the Severity enum.
                </p>
                <p>
                    This is done using the following example:
                </p>

                <SyntaxHighlighter language='dart'>
                    {`
try
{
    throw Exception("Something has gone wrong");
}
on Exception catch (e)
{
    crashCatch.reportCrash(e, Severity.LOW);
}
                `}
                </SyntaxHighlighter>

                <p>
                    Alternatively, you can pass the stack to the reportCrash method as the 3rd parameter. This is done in the example below:
                </p>

                <SyntaxHighlighter language='dart'>
                    {`
try
{
    throw Exception("Something has gone wrong");
}
on Exception catch (e, stacktrace)
{
    crashCatch.reportCrash(e, Severity.LOW, stacktrace);
}        
                `}
                </SyntaxHighlighter>

                <p>
                    There's also a fourth optional parameter on the reportCrash method where you
                    can pass a Map&lt;String, dynamic&gt; object of custom properties, which can be
                    used to provide custom information to help add debug information to help
                    you diagnose why an error occurred. An example of this is blow:
                </p>

                <SyntaxHighlighter language='dart'>
                    {`
try
{
    throw Exception("Something has gone wrong");
}
on Exception catch (e, stacktrace)
{
    crashCatch.reportCrash(e, Severity.HIGH, stack: stacktrace, customProperties: {
        "key 1": "value 1",
        "key 2": "value 2",
        "Key 3": 3
    });
}    
                `}
                </SyntaxHighlighter>

                <p>
                    For more information to see other examples of how to send crashes, check
                    the <a href='https://github.com/Crash-Catch/CrashCatchLib-Flutter' target='_blank' rel='nofollow noreferrer'>GitHub</a> repository.
                </p>
            </div>
        </>

    )
}

export default FlutterInstructions;