import * as React from 'react'
import TopNav from "../TopNav";
import FeatureBlock from "../FeatureBlock";
import ContentWrapper from "../ContentWrapper";
import Helmet from 'react-helmet';
import Footer from "../Footer";

export default function ProjectReleaseDetection(){
    return (
        <>
            <Helmet>
                <title>Crash Catch | Project Release Detection</title>
                <meta name='keywords' content='crash catch, project release detection' />
                <meta name='description' content="With Crash Catch's project release detection you can
                    see when new versions are detected by Crash Catch which are overlaid over the crash
                    graph and active user analysis so you can see how you app is performing between releases" />
            </Helmet>

            <div className='h-grow'>

                <TopNav />

                <FeatureBlock feature_title='Project Release Detection'
                              sub_heading='See project releases overlaid crash and active user graphs to easily see trends' />

                <ContentWrapper>
                    <h2>Project Release Detection</h2>
                    <p>
                        With project release detection you can see when a new version is detected
                        for a project which is overlaid on top of the active user analysis and crash
                        chart so you can see the increase or decrease of active users and crash reports
                        received for the project between different releases
                    </p>

                    <img className='shadow-md shadow-primary/60 mx-auto my-4'
                         src='/images/screenshots/active_user_crash_analysis.png' alt='Active User Analysis' />

                    <h2>Why is project release detection important?</h2>
                    With project releases overlaid the active user charts and crash charts you can
                    see trends overa period of time. For example, if you see a new project release,
                    with a trend upwards in the number of crash reports, then you know that there's likely
                    been an issue in the release, if you see the trends drop, then you can easily see that
                    the issue is resolved, along with the active user trends you can see easily whether your
                    users are happy.

                </ContentWrapper>
                <Footer />
            </div>
        </>
    )
}