import * as React from "react";
import TopNav from "../TopNav";
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";
import {useParams} from "react-router-dom";
import useBlogPost from "../../hooks/blog/useBlogPost";
import {Loading, LoadingSize} from "devso-react-library";
import parse from 'html-react-parser';
import NotFound from "../NotFound";
import DocumentMeta from "react-document-meta";

export default function BlogPost() {
    const params = useParams();
    const {blogPost, loading} = useBlogPost(params.slug);

    if (blogPost !== null && !loading)
    {
        const meta = {
            title: blogPost.meta_title,
            description: blogPost.meta_description,
            meta: {
                charset: "utf-8",
                name: {
                    keywords: blogPost.meta_keywords,
                    description: blogPost.meta_description
                }
            }
        };

        return (
            <>
                <DocumentMeta {...meta} />

                <div className='h-grow'>

                    <TopNav />

                    <ContentWrapper>
                        {
                            blogPost?.feature_image !== null ?
                                <img className='text-center mx-auto my-6' src={blogPost?.feature_image} /> : null
                        }
                        <h1>{blogPost?.title}</h1>

                        {
                            parse(blogPost?.html)
                        }
                    </ContentWrapper>
                    <Footer />
                </div>
            </>

        )
    }
    else if (loading)
    {
        return (
            <Loading size={LoadingSize.SMALL} label='Retrieving Blog Post' />
        )
    }
    else
    {
        return <NotFound />
    }

}
