import * as React from "react";
import {FeatureBlockProps} from "../models/FeatureBlock";

export default function FeatureBlock(props: FeatureBlockProps) {
    return (
        <div className='bg-primary h-[320px] -mb-20 text-white mt-[110px] pt-[20px] text-center
            shadow-md shadow-primary/80'>
            <h1 className='text-white pt-30'>{props.feature_title}</h1>
            <p className='text-white text-2xl font-bold'>{props.sub_heading}</p>

            <div className='content-center items-center justify-center justify-items-center mt-16'>
                <a className='bg-cta p-4 rounded hover:bg-primary-light text-white transition-all ease-in-out duration-300'
                   href='https://app.crashcatch.com/choose-plan'>Start 14 Day Free Trial</a>
            </div>
        </div>
    )
}