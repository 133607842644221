import * as React from "react";
import TopNav from "../TopNav";
import FeatureBlock from "../FeatureBlock";
import ContentWrapper from "../ContentWrapper";
import Helmet from 'react-helmet';
import Footer from "../Footer";

export default function RealTimeMonitoring() {
    return (
        <>
            <Helmet>
                <title>Crash Catch | Active User Analysis</title>
                <meta name='keywords' content='crash catch, real time monitoring' />
                <meta name='description' content="With Crash Catch's real time monitoring, you can
                    instantly receive a notification when a new crash or identified ensuring you can find,
                    resolve and release a fix as quickly as possible" />
            </Helmet>

            <div className='h-grow'>

                <TopNav />

                <FeatureBlock feature_title='Real Time Monitoring'
                              sub_heading="Receive a notification in near real time of any crash or error
                              that occurs ensuring the issue can be identified and resolved as quickly as possible" />

                <ContentWrapper>
                    <h2>Real Time Monitoring</h2>
                    <p>
                        With Crash Catch's real time monitoring, you receive notifications of any errors and
                        crashes within your production apps, web apps, mobile apps and APIs so you can
                        quickly identify, fix and release a new version as quickly as possible ensuring
                        your customers are not hugely impacted by a bug.
                    </p>

                    <img className='shadow-md shadow-primary/60 mx-auto my-4'
                         src='/images/screenshots/dashboard.png' alt='Active User Analysis' />

                    <h2>Why Is Real Time Monitoring Important?</h2>
                    <p>
                        If your users and customers are having issues with your products and services whether its a
                        mobile app, web app, or an API, you don't want to wait to find out there's a problem, or
                        rely on your customers/users reporting problems to you, you want to be notified ASAP so you
                        can find, resolve and release a fix as quickly as possible ensuring you have a happy
                        customers.
                    </p>
                </ContentWrapper>
                <Footer />
            </div>
        </>
    )
}