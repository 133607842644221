export function convertFullTimezoneStringToNormalString(dateTimeString: string)
{
    const parts = dateTimeString.split('T');
    const date = parts[0].trim();
    let time = parts[1].trim();

    time = time.substring(0, time.indexOf('.'));

    return date + ' ' + time;

}