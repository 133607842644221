import * as React from "react";
import GhostContentAPI from "@tryghost/content-api"
import {useEffect, useState} from "react";
import {BlogPostProps} from "../../models/BlogPostProps";

export default function useBlogPosts() {

    const [blogPosts, setBlogPosts] = useState(null);

    const compare = (a:BlogPostProps, b:BlogPostProps ) => {
        if ( a.published_at < b.published_at ){
            return -1;
        }
        if ( a.published_at > b.published_at ){
            return 1;
        }
        return 0;
    }

    const getBlogPosts = async () => {
        const api = new GhostContentAPI({
            host: 'https://blog.devso.io',
            key: '0cc4c2f6fc5a6c57b590fcb0ae',
            version: "v4"
        });

        const response = await api.posts.browse({
            include: 'tags,authors',
            filter: "tags:['crash-catch']"
        });

        response.sort(compare).reverse();

        setBlogPosts(response);

    }

    useEffect(() => {
        (
            async function () {
                await getBlogPosts()
            }
        )();
    }, []);

    return blogPosts;
}