import *  as React from "react";
import useBlogPosts from "../../hooks/blog/useBlogPosts";
import TopNav from "../TopNav";
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";
import {BlogPostProps} from "../../models/BlogPostProps";
import BlogCard from "./BlogCard";


export default function BlogHome() {

    const blogPosts : Array<BlogPostProps> = useBlogPosts();

    return (
        <div className='h-grow'>

            <TopNav />

            <ContentWrapper>
                <div className='flex flex-row flex-wrap'>
                    {
                        blogPosts !== null ?
                            blogPosts.map(post => {
                                return <BlogCard key={post.id} {...post} />
                            }) : null
                    }
                </div>

            </ContentWrapper>
            <Footer />
        </div>
    )
}