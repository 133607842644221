import * as React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import LanguageSidebar from "./LanguageSidebar";
import PHPInstructions from "../ProjectInstructions/PHPInstructions";
import Helmet from 'react-helmet';

export default function Php() {
    return (
        <>
            <Helmet>
                <title>Crash Catch | PHP Crash &amp; Error Reporting</title>
                <meta name='keywords' content='php, crash and error reporting, crash reporting, error reporting' />
                <meta name='description' content='Simple and lightweight PHP crash reporting library allowing you
                easily find and fix issues that happen in production ensuring you have happy customers and
                a happy development team' />
            </Helmet>
            <div className='h-grow'>

                <TopNav />

                <main className='!flex-grow min-h-[calc(100vh-360px)] overflow-y-scroll mt-[110px] bg-[#f2f2f2] -mb-5'>
                    <div className='w-full mx-auto'>
                        <div className='flex flex-col sm:flex-row h-grow h-full '>
                            <div className='w-full sm:w-3/4 pl-4'>
                                <h1>PHP Crash &amp; Error Reporting</h1>
                                <PHPInstructions />
                            </div>

                            <div className='w-full sm:w-1/4 bg-primary mb-0 h-full w-full min-h-full h-auto justify-end right-0'>
                                <LanguageSidebar github_repo='https://github.com/Crash-Catch/CrashCatchLib-PHP'
                                                 platform='PHP'
                                                 sdk_docs='https://docs.crashcatch.com/integrations/php'/>
                            </div>
                        </div>
                    </div>
                </main>




                <Footer />
            </div>
        </>

    )
}