import React from 'react';
import 'devso-react-library/dist/styles.css'
import './styles/globals.css'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import BlogHome from "./components/Blog/BlogHome";
import BlogPost from "./components/Blog/BlogPost";
import ErrorBoundary from "./components/ErrorBoundary";
import {CrashCatch, CrashCatchProvider, CrashCatchContext} from "crashcatchlib-reactjs";
import Languages from "./components/Languages";
import Android from "./components/Languages/Android";
import ReactJS from "./components/Languages/ReactJS";
import Php from "./components/Languages/PHP";
import Flutter from "./components/Languages/Flutter";
import Custom from "./components/Languages/Custom";
import ActiveUserAnalysis from "./components/Features/ActiveUserAnalysis";
import ProjectReleaseDetection from "./components/Features/ProjectReleaseDetection";
import EasyUnderstandableCrashReports from "./components/Features/EasyUnderstandableCrashReports";
import RealTimeMonitoring from "./components/Features/RealTimeMonitoring";
import {ScrollToTop} from "devso-react-library";
import Pricing from "./components/Pricing";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import About from "./components/About";
import OnHold from "./components/OnHold";

function App() {



    //const crash_catch = this.context;
    const crash_catch = new CrashCatch();
    crash_catch.initialiseCrashCatch("74259138", "bwk60h98i1rgcqzjnmelus7fo", "1.0.6");
  return (

      <CrashCatchProvider value={crash_catch}>
          <ErrorBoundary>
              <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                      <Route index element={<Home />} />
                      <Route path='/blog' element={<BlogHome />} />
                      <Route path='/blog/:slug' element={<BlogPost />} />

                      <Route path='/pricing' element={<Pricing /> } />
                      <Route path='/languages' element={<Languages /> } />
                      <Route path='/languages/android' element={<Android /> } />
                      <Route path='/languages/reactjs' element={<ReactJS /> } />
                      <Route path='/languages/php' element={<Php /> } />
                      <Route path='/languages/flutter' element={<Flutter /> } />
                      <Route path='/languages/custom-sdk' element={<Custom /> } />

                      <Route path='/features/active-user-analysis' element={<ActiveUserAnalysis /> } />
                      <Route path='/features/project-release-detection' element={<ProjectReleaseDetection /> } />
                      <Route path='/features/simple-understandable-crash-reports' element={<EasyUnderstandableCrashReports /> } />
                      <Route path='/features/real-time-monitoring' element={<RealTimeMonitoring /> } />

                      <Route path='/about' element={<About /> } />

                      <Route path='/privacy-policy' element={<PrivacyPolicy /> } />
                      <Route path='/terms-of-service' element={<TermsOfService /> } />

                      <Route path='/on-hold' element={<OnHold /> } />

                      <Route path='*' element={<NotFound />} />
                  </Routes>
              </BrowserRouter>
          </ErrorBoundary>
      </CrashCatchProvider>

  );
}
export default App;
