import * as React from "react";
import {useEffect, useState} from "react";
import {BlogPostProps} from "../../models/BlogPostProps";
import GhostContentAPI from "@tryghost/content-api"

export default function useBlogPost(slug: string) {
    const [blogPost, setBlogPost] = useState<BlogPostProps>(null)
    const [loading, setLoading] = useState(false);

    const getBlogPost = async (slug) => {
        const api = new GhostContentAPI({
            url: 'https://blog.devso.io',
            key: '0cc4c2f6fc5a6c57b590fcb0ae',
            version: "v4"
        });

        setLoading(true);
        const response = await api.posts.browse({
            include: 'tags,authors',
            filter: `slug:${slug}`
        });

        setBlogPost(response[0]);
        setLoading(false);
    }

    useEffect(() => {
        (
            async function() {
                await getBlogPost(slug);
            }
        )();
    }, []);

    return {blogPost, loading}
}