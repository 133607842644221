import * as React from "react";
import Helmet from 'react-helmet';
import TopNav from "../TopNav";
import ContentWrapper from "../ContentWrapper";
import FeatureBlock from "../FeatureBlock";
import Footer from "../Footer";

export default function ActiveUserAnalysis() {
    return (
        <>
            <Helmet>
                <title>Crash Catch | Active User Analysis</title>
                <meta name='keywords' content='crash catch, active user analysis, user analysis, analysis' />
                <meta name='description' content="With Crash Catch's active user analysis you can see how many active users your project is getting throughout the course of a day, week or month and see trends based on project release detection" />
            </Helmet>

            <div className='h-grow'>

                <TopNav />

                <FeatureBlock feature_title='Active User Analysis'
                    sub_heading='See how often your apps and services are being used and see trends between project releases' />

                <ContentWrapper>
                    <h2>Understand Usage and Trends</h2>

                    <p>
                        With Crash Catch, you can easily see the total number of users who are using
                        your apps, websites and services, during certain periods, such as over the course
                        of a day, a week or a month with overlaid information about when new project
                        releases are detected.
                    </p>

                    <img className='shadow-md shadow-primary/60 mx-auto my-4'
                         src='/images/screenshots/active_user_crash_analysis.png' alt='Active User Analysis' />

                    <h2>Why are usage trends important?</h2>
                    <p>
                        Usage trends are important as it can give you vital information about the state
                        of your projects. When new project versions are detected they are overlaid on the chart
                        so you can see the percentage increase or decrease of active users which might indicate
                        whether your recent project update are making your users happy or not.
                    </p>
                </ContentWrapper>
                <Footer />
            </div>
        </>
    )
}